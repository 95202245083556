import React from 'react'
import { Link } from "gatsby"

import featuredStyles from './featured_games.module.scss'
import DisplayResult from '../../../helper_js/display_game_result'



const DispFeaturedGame = ({ data }) => {

    var bwGameId = ''
    var bwTeam = ''
    var bwTeamIcon = ''
    var bwPf = ''
    var bwPa = ''
    var bwResult = ''
    var bwOpp = ''
    var bwSpread = ''

    var buGameId = ''
    var buTeam = ''
    var buTeamIcon = ''
    var buPf = ''
    var buPa = ''
    var buResult = ''
    var buOpp = ''
    var buSpread = ''

    var llGameId = ''
    var llTeam = ''
    var llTeamIcon = ''
    var llPf = ''
    var llPa = ''
    var llResult = ''
    var llOpp = ''
    var llLowWp = ''

    var game = ''

    for (game of data.allGameHistoryCsv.nodes) {

        if (parseInt(game.biggest_ats_win) === 1) {

            bwGameId = game.game_id_slug
            bwTeam = game.team
            bwTeamIcon = game.team_logo_espn_of_team
            bwPf = game.points_for
            bwPa = game.points_against
            bwResult = game.result
            bwOpp = game.opp
            bwSpread = game.home_spread_line
        
        }

        if (parseInt(game.biggest_upset) === 1) {

            buGameId = game.game_id_slug
            buTeam = game.team
            buTeamIcon = game.team_logo_espn_of_team
            buPf = game.points_for
            buPa = game.points_against
            buResult = game.result
            buOpp = game.opp
            buSpread = game.home_spread_line
        
        }

        if (parseInt(game.most_improbable_win) === 1) {

            llGameId = game.game_id_slug
            llTeam = game.team
            llTeamIcon = game.team_logo_espn_of_team
            llPf = game.points_for
            llPa = game.points_against
            llResult = game.result
            llOpp = game.opp
            llLowWp = game.lowest_win_prob
        
        }

    }

    return (
        <div className={featuredStyles.gamesContainer}>
            <Link className={featuredStyles.featuredLink}
                to={`../../../games/${bwGameId}/`}
            >
                <div className={featuredStyles.gameContainer}>
                    <p className={featuredStyles.gameType}>
                        Biggest Win
                    </p>
                    <div className={featuredStyles.team_icon_div}>
                        <div className={featuredStyles.team_icon_container}>
                            <img
                                className={featuredStyles.team_icon}
                                src={bwTeamIcon}
                                alt={bwTeam}>
                            </img>
                        </div>
                    </div>
                    <div className={featuredStyles.resultDiv}>
                        <DisplayResult
                            pf={bwPf}
                            pa={bwPa}
                            result={bwResult}
                        />
                    </div>
                    <div className={featuredStyles.opponent}>
                        vs {bwOpp}
                    </div>
                    <div className={featuredStyles.statHighlight}>
                        Margin ATS: {parseInt(bwPf) - parseInt(bwPa) + parseInt(bwSpread)}
                    </div>
                </div>
            </Link>
            <Link className={featuredStyles.featuredLink}
                to={`../../../games/${buGameId}/`}
            >
                <div className={featuredStyles.gameContainer}>
                    <p className={featuredStyles.gameType}>
                        Biggest Upset
                    </p>
                    <div className={featuredStyles.team_icon_div}>
                        <div className={featuredStyles.team_icon_container}>
                            <img
                                className={featuredStyles.team_icon}
                                src={buTeamIcon}
                                alt={buTeam}>
                            </img>
                        </div>
                    </div>
                    <div className={featuredStyles.resultDiv}>
                        <DisplayResult
                            pf={buPf}
                            pa={buPa}
                            result={buResult}
                        />
                    </div>
                    <div className={featuredStyles.opponent}>
                        vs {buOpp}
                    </div>
                    <div className={featuredStyles.statHighlight}>
                        Line: {parseInt(buSpread)}
                    </div>
                </div>
            </Link>
            <Link className={featuredStyles.featuredLink}
                to={`../../../games/${llGameId}/`}
            >
                <div className={featuredStyles.gameContainer}>
                    <p className={featuredStyles.gameType}>
                        Least Likely
                    </p>
                    <div className={featuredStyles.team_icon_div}>
                        <div className={featuredStyles.team_icon_container}>
                            <img
                                className={featuredStyles.team_icon}
                                src={llTeamIcon}
                                alt={llTeam}>
                            </img>
                        </div>
                    </div>
                    <div className={featuredStyles.resultDiv}>
                        <DisplayResult
                            pf={llPf}
                            pa={llPa}
                            result={llResult}
                        />
                    </div>
                    <div className={featuredStyles.opponent}>
                        vs {llOpp}
                    </div>
                    <div className={featuredStyles.statHighlight}>
                        Min WP%: {Math.round(1000*llLowWp)/10}%
                    </div>
                </div>
            </Link>
        </div>

    )

}

export default DispFeaturedGame