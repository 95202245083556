

const NonPassLine = ({ data, pos, rankNum}) => {

    var tries = 0
    var yds = 0
    var tds = 0

    if (pos === 'rec') {
        
        if (parseInt(rankNum) === 1) {
            tries = parseInt(data.weeklyLeadersCsv.rec_leader_rec_1)
            yds = parseInt(data.weeklyLeadersCsv.rec_leader_rec_yards_1)
            tds = parseInt(data.weeklyLeadersCsv.rec_leader_tds_1)
        } else if (parseInt(rankNum) === 2) {
            tries = parseInt(data.weeklyLeadersCsv.rec_leader_rec_2)
            yds = parseInt(data.weeklyLeadersCsv.rec_leader_rec_yards_2)
            tds = parseInt(data.weeklyLeadersCsv.rec_leader_tds_2)
        } else {
            tries = parseInt(data.weeklyLeadersCsv.rec_leader_rec_3)
            yds = parseInt(data.weeklyLeadersCsv.rec_leader_rec_yards_3)
            tds = parseInt(data.weeklyLeadersCsv.rec_leader_tds_3)         
        }

        if (tds === 0) {
            tds = ''
        } else {
            tds = tds + ' TDs'
        }
    
        return tries + ' RECs, ' + yds +', ' + tds 

    } else {

        if (parseInt(rankNum) === 1) {
            tries = parseInt(data.weeklyLeadersCsv.rush_leader_rush_1)
            yds = parseInt(data.weeklyLeadersCsv.rush_leader_rush_yards_1)
            tds = parseInt(data.weeklyLeadersCsv.rush_leader_tds_1)
        } else if (parseInt(rankNum) === 2) {
            tries = parseInt(data.weeklyLeadersCsv.rush_leader_rush_2)
            yds = parseInt(data.weeklyLeadersCsv.rush_leader_rush_yards_2)
            tds = parseInt(data.weeklyLeadersCsv.rush_leader_tds_2)
        } else {
            tries = parseInt(data.weeklyLeadersCsv.rush_leader_rush_3)
            yds = parseInt(data.weeklyLeadersCsv.rush_leader_rush_yards_3)
            tds = parseInt(data.weeklyLeadersCsv.rush_leader_tds_3)
        }

        if (tds === 0) {
            tds = ''
        } else {
            tds = tds + ' TDs'
        }

        return tries + ' CARs, ' + yds +', ' + tds 
        
    }

}

export default NonPassLine