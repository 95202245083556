const DisplayEpa = ({ data, pos, rankNum }) => {

    if (rankNum === 1) {
        if (pos === 'qb') {
            return 'Total Epa: ' + parseFloat(data.weeklyLeadersCsv.pass_leader_epa_1).toFixed(1)
        } if (pos === 'rec') {
            return 'Total Epa: ' + parseFloat(data.weeklyLeadersCsv.rec_leader_epa_1).toFixed(1)
        } else {
            return 'Total Epa: ' + parseFloat(data.weeklyLeadersCsv.rush_leader_epa_1).toFixed(1)
        }
    } if (rankNum === 2) {
        if (pos === 'qb') {
            return 'Total Epa: ' + parseFloat(data.weeklyLeadersCsv.pass_leader_epa_2).toFixed(1)
        } if (pos === 'rec') {
            return 'Total Epa: ' + parseFloat(data.weeklyLeadersCsv.rec_leader_epa_2).toFixed(1)
        } else {
            return 'Total Epa: ' + parseFloat(data.weeklyLeadersCsv.rush_leader_epa_2).toFixed(1)
        }
    } else {
        if (pos === 'qb') {
            return 'Total Epa: ' + parseFloat(data.weeklyLeadersCsv.pass_leader_epa_3).toFixed(1)
        } if (pos === 'rec') {
            return 'Total Epa: ' + parseFloat(data.weeklyLeadersCsv.rec_leader_epa_3).toFixed(1)
        } else {
            return 'Total Epa: ' + parseFloat(data.weeklyLeadersCsv.rush_leader_epa_3).toFixed(1)
        }
    }

}

export default DisplayEpa