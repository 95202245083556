import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/layout'
import Head from '../../components/head'


import mainStyles from './weekly_summary.module.scss'
import TopPerformers from './weekly_components/top_performers'
import FeaturedGames from './weekly_components/featured_games'
import AllGames from './weekly_components/all_games'
import WeekLink from './weekly_components/nav_buttons'




export const query = graphql`
    query (
        $week: String!
        $season: String! 
    ) {
        weeklyLeadersCsv (
            week : {
                eq : $week
            }
            season : {
                eq : $season
            }
        ) {
            season
            week
            previous_week
            previous_season
            next_week
            next_season
            pass_leader_game_id_1_slug
            pass_leader_1
            pass_leader_id_1
            pass_leader_headshot_1
            pass_leader_att_1
            pass_leader_comp_1
            pass_leader_yards_1
            pass_leader_tds_1
            pass_leader_ints_1
            pass_leader_epa_1
            rec_leader_game_id_1_slug
            rec_leader_1
            rec_leader_id_1
            rec_leader_headshot_1
            rec_leader_rec_1
            rec_leader_rec_yards_1
            rec_leader_tds_1
            rec_leader_epa_1
            rush_leader_game_id_1_slug
            rush_leader_1
            rush_leader_id_1
            rush_leader_headshot_1
            rush_leader_rush_1
            rush_leader_rush_yards_1
            rush_leader_tds_1
            rush_leader_epa_1
            pass_leader_game_id_2_slug
            pass_leader_2
            pass_leader_id_2
            pass_leader_headshot_2
            pass_leader_att_2
            pass_leader_comp_2
            pass_leader_yards_2
            pass_leader_tds_2
            pass_leader_ints_2
            pass_leader_epa_2
            rec_leader_game_id_2_slug
            rec_leader_2
            rec_leader_id_2
            rec_leader_headshot_2
            rec_leader_rec_2
            rec_leader_rec_yards_2
            rec_leader_tds_2
            rec_leader_epa_2
            rush_leader_game_id_2_slug
            rush_leader_2
            rush_leader_id_2
            rush_leader_headshot_2
            rush_leader_rush_2
            rush_leader_rush_yards_2
            rush_leader_tds_2
            rush_leader_epa_2
            pass_leader_game_id_3_slug
            pass_leader_3
            pass_leader_id_3
            pass_leader_headshot_3
            pass_leader_att_3
            pass_leader_comp_3
            pass_leader_yards_3
            pass_leader_tds_3
            pass_leader_ints_3
            pass_leader_epa_3
            rec_leader_game_id_3_slug
            rec_leader_3
            rec_leader_id_3
            rec_leader_headshot_3
            rec_leader_rec_3
            rec_leader_rec_yards_3
            rec_leader_tds_3
            rec_leader_epa_3
            rush_leader_game_id_3_slug
            rush_leader_3
            rush_leader_id_3
            rush_leader_headshot_3
            rush_leader_rush_3
            rush_leader_rush_yards_3
            rush_leader_tds_3
            rush_leader_epa_3
        }
        allGameHistoryCsv(
            sort: {
                fields: nfelo_change_rank,
                order: ASC
            }
            filter: {
              week: {
                in: [$week]
              }
              season: {
                in: [$season]
              }
            }
        ) {
            nodes {
                game_id
                game_id_slug
                season
                week
                team
                opp
                location
                points_for
                points_against
                home_spread_line
                result
                spread_result
                blended_margin
                blended_margin_delta
                nfelo
                nfelo_raw
                nfelo_change
                league_best_nfelo
                league_worst_nfelo
                team_logo_espn
                biggest_ats_win
                biggest_upset
                most_improbable_win
                lowest_win_prob
                team_logo_espn_of_team
            }
        }
    }
`


const WeekPage = (props) => {

    return (
        <Layout>
            <Head title={`Week ${props.data.weeklyLeadersCsv.week}, ${props.data.weeklyLeadersCsv.season} Recap`}
                pageDescription={`NFL analytics recap for week ${props.data.weeklyLeadersCsv.week}, ${props.data.weeklyLeadersCsv.season}`}/>
            <div className={mainStyles.weekContainer}>
                <h1 className={mainStyles.weeklyHeader}>
                    NFL Analytics Recap for Week {props.data.weeklyLeadersCsv.week}, {props.data.weeklyLeadersCsv.season}
                </h1>
                <div className={mainStyles.navBox}>
                    <WeekLink
                        prevSeason={props.data.weeklyLeadersCsv.previous_season}
                        prevWeek={props.data.weeklyLeadersCsv.previous_week}
                        nextSeason={props.data.weeklyLeadersCsv.next_season}
                        nextWeek={props.data.weeklyLeadersCsv.next_week}
                        linkType={'previous'}
                    />
                    <WeekLink
                        prevSeason={props.data.weeklyLeadersCsv.previous_season}
                        prevWeek={props.data.weeklyLeadersCsv.previous_week}
                        nextSeason={props.data.weeklyLeadersCsv.next_season}
                        nextWeek={props.data.weeklyLeadersCsv.next_week}
                        linkType={'next'}
                    />
                </div>
                <FeaturedGames
                    data={props.data}
                />
                <TopPerformers
                    data={props.data}
                />
                <AllGames
                    data={props.data}
                />
            </div>
        </Layout>
    )
}

export default WeekPage