import React from 'react'

import featuredStyles from './featured_games.module.scss'
import DispFeaturedGame from './display_featured_game'


const FeaturedGames = (props) => {

    return (
        <div className={featuredStyles.mainContainer}>
            <h1 className={featuredStyles.containerHeader}>
                Featured Games
            </h1>
            <DispFeaturedGame
                data={props.data}
            />
        </div>
    )

}

export default FeaturedGames