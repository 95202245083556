import React from 'react'
import { Link } from "gatsby"

import PassLine from './display_pass_line'
import NonPassLine from './display_non_pass_line'
import DisplayEpa from './display_epa'

import performerStyles from './top_performers.module.scss'


const TopPerformers = (props) => {

    return (
        <div className={performerStyles.mainContainer}>
            <h1 className={performerStyles.containerHeader}>
                Top Performers
            </h1>
            <div className={performerStyles.leaderContainer}>
                <div className={performerStyles.categoryContainer}>
                    <div className={performerStyles.leaderType}>
                        Passing
                    </div>
                    <Link className={performerStyles.leaderGameLink}
                        to={`../../../games/${props.data.weeklyLeadersCsv.pass_leader_game_id_1_slug}/`}
                    >
                        <div className={performerStyles.individualLeaderBox}>
                            <div className={performerStyles.player_icon_div}>
                                <div className={performerStyles.player_icon_container}>
                                    <img
                                        className={performerStyles.player_icon}
                                        src={props.data.weeklyLeadersCsv.pass_leader_headshot_1}
                                        alt={props.data.weeklyLeadersCsv.pass_leader_1}>
                                    </img>
                                </div>
                            </div>
                            <div className={performerStyles.player_stat_div}>
                                <p className={performerStyles.player_name}>
                                    {props.data.weeklyLeadersCsv.pass_leader_1} &#129351;
                                </p>
                                <p className={performerStyles.player_stat}>
                                    <PassLine
                                        data={props.data}
                                        rankNum={1}
                                    />
                                </p>
                                <p className={performerStyles.player_stat}>
                                    <DisplayEpa
                                        data={props.data}
                                        rankNum={1}
                                        pos={'qb'}
                                    />
                                </p>
                            </div>
                        </div>
                    </Link>
                    <Link className={performerStyles.leaderGameLink}
                        to={`../../../games/${props.data.weeklyLeadersCsv.pass_leader_game_id_2_slug}/`}
                    >
                        <div className={performerStyles.individualLeaderBox}>
                            <div className={performerStyles.player_icon_div}>
                                <div className={performerStyles.player_icon_container}>
                                    <img
                                        className={performerStyles.player_icon}
                                        src={props.data.weeklyLeadersCsv.pass_leader_headshot_2}
                                        alt={props.data.weeklyLeadersCsv.pass_leader_2}>
                                    </img>
                                </div>
                            </div>
                            <div className={performerStyles.player_stat_div}>
                                <p className={performerStyles.player_name}>
                                    {props.data.weeklyLeadersCsv.pass_leader_2} &#129352;
                                </p>
                                <p className={performerStyles.player_stat}>
                                    <PassLine
                                        data={props.data}
                                        rankNum={2}
                                    />
                                </p>
                                <p className={performerStyles.player_stat}>
                                    <DisplayEpa
                                        data={props.data}
                                        rankNum={2}
                                        pos={'qb'}
                                    />
                                </p>
                            </div>
                        </div>
                    </Link>
                    <Link className={performerStyles.leaderGameLink}
                        to={`../../../games/${props.data.weeklyLeadersCsv.pass_leader_game_id_3_slug}/`}
                    >
                        <div className={performerStyles.individualLeaderBox}>
                            <div className={performerStyles.player_icon_div}>
                                <div className={performerStyles.player_icon_container}>
                                    <img
                                        className={performerStyles.player_icon}
                                        src={props.data.weeklyLeadersCsv.pass_leader_headshot_3}
                                        alt={props.data.weeklyLeadersCsv.pass_leader_3}>
                                    </img>
                                </div>
                            </div>
                            <div className={performerStyles.player_stat_div}>
                                <p className={performerStyles.player_name}>
                                    {props.data.weeklyLeadersCsv.pass_leader_3} &#129353;
                                </p>
                                <p className={performerStyles.player_stat}>
                                    <PassLine
                                        data={props.data}
                                        rankNum={3}
                                    />
                                </p>
                                <p className={performerStyles.player_stat}>
                                    <DisplayEpa
                                        data={props.data}
                                        rankNum={3}
                                        pos={'qb'}
                                    />
                                </p>
                            </div>
                        </div>
                    </Link>
                </div>


                <div className={performerStyles.categoryContainer}>
                    <div className={performerStyles.leaderType}>
                        Receiving
                    </div>
                    <Link className={performerStyles.leaderGameLink}
                        to={`../../../games/${props.data.weeklyLeadersCsv.rec_leader_game_id_1_slug}/`}
                    >
                        <div className={performerStyles.individualLeaderBox}>
                            <div className={performerStyles.player_icon_div}>
                                <div className={performerStyles.player_icon_container}>
                                    <img
                                        className={performerStyles.player_icon}
                                        src={props.data.weeklyLeadersCsv.rec_leader_headshot_1}
                                        alt={props.data.weeklyLeadersCsv.rec_leader_1}>
                                    </img>
                                </div>
                            </div>
                            <div className={performerStyles.player_stat_div}>
                                <p className={performerStyles.player_name}>
                                    {props.data.weeklyLeadersCsv.rec_leader_1} &#129351;
                                </p>
                                <p className={performerStyles.player_stat}>
                                    <NonPassLine
                                        data={props.data}
                                        pos={'rec'}
                                        rankNum={1}
                                    />
                                </p>
                                <p className={performerStyles.player_stat}>
                                    <DisplayEpa
                                        data={props.data}
                                        rankNum={1}
                                        pos={'rec'}
                                    />
                                </p>
                            </div>
                        </div>
                    </Link>
                    <Link className={performerStyles.leaderGameLink}
                        to={`../../../games/${props.data.weeklyLeadersCsv.rec_leader_game_id_2_slug}/`}
                    >
                        <div className={performerStyles.individualLeaderBox}>
                            <div className={performerStyles.player_icon_div}>
                                <div className={performerStyles.player_icon_container}>
                                    <img
                                        className={performerStyles.player_icon}
                                        src={props.data.weeklyLeadersCsv.rec_leader_headshot_2}
                                        alt={props.data.weeklyLeadersCsv.rec_leader_2}>
                                    </img>
                                </div>
                            </div>
                            <div className={performerStyles.player_stat_div}>
                                <p className={performerStyles.player_name}>
                                    {props.data.weeklyLeadersCsv.rec_leader_2} &#129352;
                                </p>
                                <p className={performerStyles.player_stat}>
                                    <NonPassLine
                                        data={props.data}
                                        pos={'rec'}
                                        rankNum={2}
                                    />
                                </p>
                                <p className={performerStyles.player_stat}>
                                    <DisplayEpa
                                        data={props.data}
                                        rankNum={2}
                                        pos={'rec'}
                                    />
                                </p>
                            </div>
                        </div>
                    </Link>
                    <Link className={performerStyles.leaderGameLink}
                        to={`../../../games/${props.data.weeklyLeadersCsv.rec_leader_game_id_3_slug}/`}
                    >
                    <div className={performerStyles.individualLeaderBox}>
                        <div className={performerStyles.player_icon_div}>
                            <div className={performerStyles.player_icon_container}>
                                <img
                                    className={performerStyles.player_icon}
                                    src={props.data.weeklyLeadersCsv.rec_leader_headshot_3}
                                    alt={props.data.weeklyLeadersCsv.rec_leader_3}>
                                </img>
                            </div>
                        </div>
                        <div className={performerStyles.player_stat_div}>
                            <p className={performerStyles.player_name}>
                                {props.data.weeklyLeadersCsv.rec_leader_3} &#129353;
                            </p>
                            <p className={performerStyles.player_stat}>
                                <NonPassLine
                                    data={props.data}
                                    pos={'rec'}
                                    rankNum={3}
                                />
                            </p>
                            <p className={performerStyles.player_stat}>
                                <DisplayEpa
                                    data={props.data}
                                    rankNum={3}
                                    pos={'rec'}
                                />
                            </p>
                        </div>
                    </div>
                    </Link>
                </div>

                <div className={performerStyles.categoryContainer}>
                    <div className={performerStyles.leaderType}>
                        Rushing
                    </div>
                    <Link className={performerStyles.leaderGameLink}
                        to={`../../../games/${props.data.weeklyLeadersCsv.rush_leader_game_id_1_slug}/`}
                    >
                        <div className={performerStyles.individualLeaderBox}>
                            <div className={performerStyles.player_icon_div}>
                                <div className={performerStyles.player_icon_container}>
                                    <img
                                        className={performerStyles.player_icon}
                                        src={props.data.weeklyLeadersCsv.rush_leader_headshot_1}
                                        alt={props.data.weeklyLeadersCsv.rush_leader_1}>
                                    </img>
                                </div>
                            </div>
                            <div className={performerStyles.player_stat_div}>
                                <p className={performerStyles.player_name}>
                                    {props.data.weeklyLeadersCsv.rush_leader_1} &#129351;
                                </p>
                                <p className={performerStyles.player_stat}>
                                    <NonPassLine
                                        data={props.data}
                                        pos={'rush'}
                                        rankNum={1}
                                    />
                                </p>
                                <p className={performerStyles.player_stat}>
                                    <DisplayEpa
                                        data={props.data}
                                        rankNum={1}
                                        pos={'rush'}
                                    />
                                </p>
                            </div>
                        </div>
                    </Link>
                    <Link className={performerStyles.leaderGameLink}
                        to={`../../../games/${props.data.weeklyLeadersCsv.rush_leader_game_id_2_slug}/`}
                    >
                        <div className={performerStyles.individualLeaderBox}>
                            <div className={performerStyles.player_icon_div}>
                                <div className={performerStyles.player_icon_container}>
                                    <img
                                        className={performerStyles.player_icon}
                                        src={props.data.weeklyLeadersCsv.rush_leader_headshot_2}
                                        alt={props.data.weeklyLeadersCsv.rush_leader_2}>
                                    </img>
                                </div>
                            </div>
                            <div className={performerStyles.player_stat_div}>
                                <p className={performerStyles.player_name}>
                                    {props.data.weeklyLeadersCsv.rush_leader_2} &#129352;
                                </p>
                                <p className={performerStyles.player_stat}>
                                    <NonPassLine
                                        data={props.data}
                                        pos={'rush'}
                                        rankNum={2}
                                    />
                                </p>
                                <p className={performerStyles.player_stat}>
                                    <DisplayEpa
                                        data={props.data}
                                        rankNum={2}
                                        pos={'rush'}
                                    />
                                </p>
                            </div>
                        </div>
                    </Link>
                    <Link className={performerStyles.leaderGameLink}
                        to={`../../../games/${props.data.weeklyLeadersCsv.rush_leader_game_id_3_slug}/`}
                    >
                    <div className={performerStyles.individualLeaderBox}>
                        <div className={performerStyles.player_icon_div}>
                            <div className={performerStyles.player_icon_container}>
                                <img
                                    className={performerStyles.player_icon}
                                    src={props.data.weeklyLeadersCsv.rush_leader_headshot_3}
                                    alt={props.data.weeklyLeadersCsv.rush_leader_3}>
                                </img>
                            </div>
                        </div>
                        <div className={performerStyles.player_stat_div}>
                            <p className={performerStyles.player_name}>
                                {props.data.weeklyLeadersCsv.rush_leader_3} &#129353;
                            </p>
                            <p className={performerStyles.player_stat}>
                                <NonPassLine
                                    data={props.data}
                                    pos={'rush'}
                                    rankNum={3}
                                />
                            </p>
                            <p className={performerStyles.player_stat}>
                                <DisplayEpa
                                    data={props.data}
                                    rankNum={3}
                                    pos={'rush'}
                                />
                            </p>
                        </div>
                    </div>
                    </Link>
                </div>


            </div>
        </div>
    )

}

export default TopPerformers