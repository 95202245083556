

const PassLine = ({ data, rankNum }) => {

    var comp = 0
    var att = 0
    var yds = 0
    var tds = 0
    var ints = 0
    
    if (rankNum === 1) {
        comp = parseInt(data.weeklyLeadersCsv.pass_leader_comp_1)
        att = parseInt(data.weeklyLeadersCsv.pass_leader_att_1)
        yds = parseInt(data.weeklyLeadersCsv.pass_leader_yards_1)
        tds = parseInt(data.weeklyLeadersCsv.pass_leader_tds_1)
        ints = parseInt(data.weeklyLeadersCsv.pass_leader_ints_1)
    } else if (rankNum === 2) {
        comp = parseInt(data.weeklyLeadersCsv.pass_leader_comp_2)
        att = parseInt(data.weeklyLeadersCsv.pass_leader_att_2)
        yds = parseInt(data.weeklyLeadersCsv.pass_leader_yards_2)
        tds = parseInt(data.weeklyLeadersCsv.pass_leader_tds_2)
        ints = parseInt(data.weeklyLeadersCsv.pass_leader_ints_2)   
    } else {
        comp = parseInt(data.weeklyLeadersCsv.pass_leader_comp_3)
        att = parseInt(data.weeklyLeadersCsv.pass_leader_att_3)
        yds = parseInt(data.weeklyLeadersCsv.pass_leader_yards_3)
        tds = parseInt(data.weeklyLeadersCsv.pass_leader_tds_3)
        ints = parseInt(data.weeklyLeadersCsv.pass_leader_ints_3)    
    }

    if (tds === 0) {
        tds = ''
    } else {
        tds = tds + ' TD, '
    }

    if (ints === 0) {
        ints = ''
    } else {
        ints = ints + ' INT'
    }

    return comp + '-' + att + ', ' + yds + ', ' + tds + ints

}

export default PassLine