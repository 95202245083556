import React from 'react'
import { Link } from "gatsby"
import tinycolor from 'tinycolor2'

import DisplayResult from '../../../helper_js/display_game_result'
import DisplayWL from '../../../helper_js/display_win_loss'

import allStyles from './all_games.module.scss'


const GameRow = ( { team, opp, game_id_slug, home_spread_line, points_for, points_against, result, spread_result, location, blended_margin_delta, nfelo_change, team_logo, opp_logo} ) => {


        const neg_color = '#ffabc0'
        const zero_color = '#ffffff'
        const pos_text = '#65e0b7'

        var deltaColor = ''
        var nfeloColor = ''
        var locationSymbol = ''

        if (blended_margin_delta <= 0) {
            deltaColor = tinycolor.mix(zero_color, neg_color, Math.abs(blended_margin_delta) * 25)
        } else {
            deltaColor = tinycolor.mix(zero_color, pos_text, Math.abs(blended_margin_delta) * 25)
        }

        if (nfelo_change <= 0) {
            nfeloColor = tinycolor.mix(zero_color, neg_color, Math.abs(nfelo_change) * 10)
        } else {
            nfeloColor = tinycolor.mix(zero_color, pos_text, Math.abs(nfelo_change) * 10)
        }

        if (location === 'H') {
            locationSymbol = ' vs '
        } else {
            locationSymbol = ' @  '
        }

        return (
            <tr className={allStyles.gameRow}>    
                <td width={"21%"}>
                    <Link className={allStyles.gameLink} to={`/games/${game_id_slug}/`}>
                        <div className={allStyles.linkDiv} style={{paddingTop:'0px', paddingBottom:'0px'}}>
                            <div className={allStyles.matchupContainer}>
                                <div className={allStyles.logoContainer}>
                                    <img
                                        className={allStyles.logoImg}
                                        src={team_logo}
                                        alt={team}
                                    />
                                    <p className={allStyles.location}>
                                        {locationSymbol}
                                    </p>
                                    <img
                                        className={allStyles.logoImg}
                                        src={opp_logo}
                                        alt={opp}
                                    />
                                </div>
                            </div>
                        </div>
                    </Link>
                </td>
                <td width={"15%"}>
                    <Link className={allStyles.oppGameLink} to={`/games/${game_id_slug}/`}>
                        <div className={allStyles.linkDiv}>
                            <DisplayResult
                                result={result}
                                pf={points_for}
                                pa={points_against}
                            />
                        </div>
                    </Link>
                </td>
                <td width={"5%"} className={allStyles.gameBorderRight}>
                    <Link className={allStyles.oppGameLink} to={`/games/${game_id_slug}/`}>
                        <div className={allStyles.linkDiv}>
                            {parseInt(points_for - points_against)}
                        </div>
                    </Link>
                </td>
                <td width={"10%"}>
                    <Link className={allStyles.oppGameLink} to={`/games/${game_id_slug}/`}>
                        <div className={allStyles.linkDiv}>
                            {home_spread_line}
                        </div>
                    </Link>
                </td>
                <td className={allStyles.gameBorderRight} width={"9%"}>
                    <Link className={allStyles.oppGameLink} to={`/games/${game_id_slug}/`}>
                        <div className={allStyles.linkDiv}>
                            <DisplayWL
                                result={spread_result}
                            />
                        </div>
                    </Link>
                </td>
                <td className={allStyles.gameBorderRight} width={"8%"} style={{color : deltaColor,}}>
                    <Link className={allStyles.oppGameLink} to={`/games/${game_id_slug}/`}>
                        <div className={allStyles.linkDiv}>
                            <span style={{color : deltaColor,}}>{Math.round(10*blended_margin_delta)/10}</span>
                        </div>
                    </Link>
                </td>
                <td width={"12.5%"}>
                    <Link className={allStyles.oppGameLink} to={`/games/${game_id_slug}/`}>
                        <div className={allStyles.linkDiv}>
                            <span style={{color : nfeloColor}}>{parseInt(nfelo_change)}</span>
                        </div>
                    </Link>
                </td>
            </tr>
        )

}

export default GameRow


