import React from 'react'
import { Link } from "gatsby"

import mainStyles from '../weekly_summary.module.scss'

const WeekLink = ( { prevWeek, prevSeason, nextWeek, nextSeason, linkType } ) => {

    var linkPath = ''

    if (linkType === 'previous') {

        if (parseInt(prevWeek) === 0) {

            return (
                <div className={mainStyles.backButtonDiv}>
                    <Link to={'/games/'} style={{textDecoration:"none", color:"inherit"}}>Current Week</Link>
                </div>
            )

        } else {

            linkPath = '/weeks/' + parseInt(prevSeason) + '/' + parseInt(prevWeek) + '/'

            return (
                <div className={mainStyles.backButtonDiv}>
                    <Link to={linkPath} style={{textDecoration:"none", color:"inherit"}}>&#9669; Week {parseInt(prevWeek)}, {parseInt(prevSeason)}</Link>
                </div>
            )    

        }

    } else {

        if (parseInt(nextWeek) === 0) {

            return(
                <div className={mainStyles.backButtonDiv}>
                    <Link to={'/games/'} style={{textDecoration:"none", color:"inherit"}}>Current Week</Link>
                </div>
            )
        
        } else {

            linkPath = '/weeks/' + parseInt(nextSeason) + '/' + parseInt(nextWeek) +'/'

            return (
                <div className={mainStyles.backButtonDiv}>
                    <Link to={linkPath} style={{textDecoration:"none", color:"inherit"}}>Week {parseInt(nextWeek)}, {parseInt(nextSeason)} &#9659;</Link>
                </div>
            )    
        }

    }

}

export default WeekLink