import React from 'react'

import allStyles from './all_games.module.scss'
import GameRow from './game_rows'



const AllGames = (props) => {

    return (

        <div className={allStyles.mainContainer}>
            <h1 className={allStyles.containerHeader}>
                All Teams
            </h1>
            <div className={allStyles.tableContainer}>
                <table className={allStyles.gameTable}>
                    <thead>
                        <tr>
                            <th className={allStyles.tableHeader}>
                                Game
                            </th>
                            <th className={allStyles.tableHeader}>
                                Result
                            </th>
                            <th className={allStyles.tableHeader}>
                                Margin
                            </th>
                            <th className={allStyles.tableHeader}>
                                Spread
                            </th>
                            <th className={allStyles.tableHeader}>
                                ATS Result
                            </th>
                            <th className={allStyles.tableHeader}>
                                Blended Dif
                            </th>
                            <th className={allStyles.tableHeader}>
                                nfelo Change
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.data.allGameHistoryCsv.nodes.map(node => {
                            return (
                                <GameRow
                                    team={node.team}
                                    opp={node.opp}
                                    location={node.location}
                                    game_id_slug={node.game_id_slug}
                                    home_spread_line={node.home_spread_line}
                                    points_for={node.points_for}
                                    points_against={node.points_against}
                                    result={node.result}
                                    spread_result={node.spread_result}
                                    blended_margin={node.blended_margin}
                                    blended_margin_delta={node.blended_margin_delta}
                                    nfelo={node.neflo}
                                    nfelo_change={node.nfelo_change}
                                    team_logo={node.team_logo_espn_of_team}
                                    opp_logo={node.team_logo_espn}
                                />
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    
    )

}

export default AllGames